import React from 'react';
import Form from  '../components/Form';


export default function Forget(props) {
    return <Form form={ FORMITEMLIST }/>
}

//立即报名
const FORMITEMLIST = {
  type:'forget',tabs:[
    {label:'登录',type:'login'},
    {label:'密码找回',type:'forget'},
  ],
  list:[
    {label:'手机号',placeholder:'请输入11位手机号',required:true,name:'phone',valid:['moblie'],type:'input'},
    {label:'验证码',placeholder:'请输入验证码',required:true,name:'validateCode',type:'input',type2:'number',sms:true},
    {label:'',name:"nolink",type:'link'},
    {label:'下一步',name:'stepbtn',type:'button',type2:'reset',params:'confirm'},
    {label:'请使用RAYS编辑账号登录'},
    {label:'如暂无RAYS编辑账号，请联系<a href="tel:027-87689225">027-87689225</a>（雷老师）进行开通。', html: true}
  ]
}